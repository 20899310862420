import axios from "axios";
import { http_request } from "../http/AxiosInstance";
import { celebApi } from "../api";
import { notifyError } from "@/utils/NotificationFunctions";
import { FetchGalleriesI } from "@/interfaces/Admin";
import { CurrentUserI } from "@/interfaces/User";
import { FetchInboxesParamsI } from "@/interfaces/Inbox";
let source: any;

export async function fetchNotAcceptedGalleries(
  page: number,
  searchText: string,
  notSkip: boolean
) {
  if (source) {
    source.cancel("Operation canceled due to new request.");
  }

  // Create a new CancelToken source for this search
  source = axios.CancelToken.source();
  return axios
    .post(`${celebApi}/admin/getNotAccepted`, {
      query: { page, searchText, notSkip },
    })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      if (axios.isCancel(error)) {
        console.log("Request canceled", error.message);
      } else {
        notifyError("Connection error");
        return error;
      }
    });
}

export async function fetchAcceptedGalleries({
  page,
  searchText,
  notSkip,
  type,
  searchFilter,
  special,
  sortTypeAllUsers,
}: FetchGalleriesI) {
  if (source) {
    source.cancel("Operation canceled due to new request.");
  }

  // Create a new CancelToken source for this search
  source = axios.CancelToken.source();
  return axios
    .post(`${celebApi}/admin/getCategories`, {
      query: {
        page,
        searchText,
        notSkip,
        type,
        searchFilter,
        special,
        sortTypeAllUsers,
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      if (axios.isCancel(error)) {
        console.log("Request canceled", error.message);
      } else {
        notifyError("Connection error");
        return error;
      }
    });
}

export async function updateGallery(gallery_data: any, userToken: string) {
  const httpOptions = {
    headers: {
      Authorization: userToken,
    },
  };
  return axios
    .post(`${celebApi}/admin/updateGallery`, gallery_data, httpOptions)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      notifyError("Connection error");
      return error;
    });
}

export async function updateGalleryAvatar(
  formData: FormData,
  userToken: string
) {
  const httpOptions = {
    headers: {
      Authorization: userToken,
    },
  };
  return axios
    .post(`${celebApi}/admin/updateAvatarGallery`, formData, httpOptions)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      notifyError("Connection error");
      return error;
    });
}

export async function deleteGalleryById(id: string, userToken: string) {
  const httpOptions = {
    headers: {
      Authorization: userToken,
    },
  };
  return axios
    .post(`${celebApi}/admin/deleteCategory`, { _id: id }, httpOptions)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      notifyError("Connection error");
      return error;
    });
}
export async function deleteUserById(userId: string, userToken: string) {
  const httpOptions = {
    headers: {
      Authorization: userToken,
    },
  };
  return axios
    .delete(`${celebApi}/admin/deleteUser/${userId}`, httpOptions)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      notifyError("Connection error");
      console.error(error);
      return error;
    });
}

export async function updateUser(
  userToken: string,
  userData: Partial<CurrentUserI>
) {
  const httpOptions = {
    headers: {
      Authorization: userToken,
    },
  };
  return axios
    .put(`${celebApi}/admin/updateUser`, userData, httpOptions)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      notifyError("Connection error");
      return error;
    });
}
export async function fetchAllUsers(
  userToken: string,
  page: number,
  searchText: string,
  notSkip: boolean,
  searchFilter: string,
  sort: string,
  date1: string,
  date2: string
) {
  const httpOptions = {
    headers: {
      Authorization: userToken,
    },
  };

  return axios
    .post(
      `${celebApi}/admin/getUsers`,
      {
        query: { page, searchText, notSkip, searchFilter, sort, date1, date2 },
      },
      httpOptions
    )
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      notifyError("Connection error");
      return error;
    });
}

export async function fetchReferrals(
  userId: string,
  userToken: string,
  sortField: string,
  sortOrder: string
) {
  const httpOptions = {
    headers: {
      Authorization: userToken,
    },
  };

  return axios
    .post(
      `${celebApi}/admin/getReferences`,
      { userId, sortField, sortOrder },
      httpOptions
    )
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      notifyError("Connection error");
      return error;
    });
}
export async function fetchModeratorsAndExModerators(userToken: string) {
  const httpOptions = {
    headers: {
      Authorization: userToken,
    },
  };
  return axios
    .get(`${celebApi}/admin/getModeratorsAndExModerators`, httpOptions)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      notifyError("Connection error");
      return error;
    });
}
export async function fetchAllUsersLogs(
  userToken: string,
  page: number,
  searchText: string,
  notSkip: boolean
) {
  const httpOptions = {
    headers: {
      Authorization: userToken,
    },
  };

  if (source) {
    source.cancel("Operation canceled due to new request.");
  }

  // Create a new CancelToken source for this search
  source = axios.CancelToken.source();
  return axios
    .post(
      `${celebApi}/admin/getUsersLogs`,
      { query: { page, searchText, notSkip } },
      httpOptions
    )
    .then((res) => {
      return res;
    })
    .catch((error) => {
      if (axios.isCancel(error)) {
        console.log("Request canceled", error.message);
      } else {
        notifyError("Connection error");
        return error;
      }
    });
}

export async function updateUserRole(
  role: number,
  userId: string,
  userToken: string
) {
  const httpOptions = {
    headers: {
      Authorization: userToken,
    },
  };

  return axios
    .put(
      `${celebApi}/admin/updateUser`,
      { admin: role, _id: userId },
      httpOptions
    )
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      notifyError("Connection error");
      return error;
    });
}

export async function updateUserReadOnly(
  readOnlyData: any,
  userId: string,
  userToken: string
) {
  const httpOptions = {
    headers: {
      Authorization: userToken,
    },
  };

  return axios
    .put(
      `${celebApi}/admin/updateUser`,
      { read_only: readOnlyData, status: "ban", _id: userId },
      httpOptions
    )
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      notifyError("Connection error");
      return error;
    });
}

export async function updateUserSuspend(
  suspendData: any,
  userId: string,
  userToken: string
) {
  const httpOptions = {
    headers: {
      Authorization: userToken,
    },
  };

  return axios
    .put(
      `${celebApi}/admin/updateUser`,
      { suspend: suspendData, status: "suspended", _id: userId },
      httpOptions
    )
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      notifyError("Connection error");
      return error;
    });
}

export async function getReportedElements(
  userToken: string,
  page: number,
  searchText: string,
  notSkip: boolean
) {
  const httpOptions = {
    headers: {
      Authorization: userToken,
    },
  };

  return axios
    .post(
      `${celebApi}/admin/report`,
      { query: { page, searchText, notSkip } },
      httpOptions
    )
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error;
    });
}

export async function fetchViewsRatingReport(
  userToken: string,
  page: number,
  searchText: string,
  notSkip: boolean,
  searchFilter: string
) {
  const httpOptions = {
    headers: {
      Authorization: userToken,
    },
  };
  return axios
    .post(
      `${celebApi}/admin/viewsReport`,
      { query: { page, searchText, notSkip, searchFilter } },
      httpOptions
    )
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error;
    });
}

export async function changeUserPoints(
  userToken: string,
  points: number,
  userId: string,
  reason: string,
  admin_name: string
) {
  const httpOptions = {
    headers: {
      Authorization: userToken,
    },
  };

  return axios
    .post(
      `${celebApi}/admin/modifyPoints`,
      { points: points, _id: userId, reason: reason, admin_name: admin_name },
      httpOptions
    )
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error;
    });
}

export async function fetchInboxes(
  { page, searchText, notSkip, searchFilter, type }: FetchInboxesParamsI,
  userToken: string
) {
  if (source) {
    source.cancel("Operation canceled due to new request.");
  }

  const httpOptions = {
    headers: {
      Authorization: userToken,
    },
  };
  // Create a new CancelToken source for this search
  source = axios.CancelToken.source();
  return axios
    .post(
      `${celebApi}/admin/getInboxes`,
      {
        page,
        searchText,
        notSkip,
        searchFilter,
        type,
      },
      httpOptions
    )
    .then((res) => {
      return res;
    })
    .catch((error) => {
      if (axios.isCancel(error)) {
        console.log("Request canceled", error.message);
      } else {
        notifyError("Connection error");
        return error;
      }
    });
}

export async function newChatByInbox(
  userId: string,
  inboxId: string,
  userToken: string
) {
  const httpOptions = {
    headers: {
      Authorization: userToken,
    },
  };

  return axios
    .post(`${celebApi}/admin/newChat`, { userId, inboxId }, httpOptions)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error;
    });
}

export async function markInboxAsSolved(inboxId: string, userToken: string) {
  const httpOptions = {
    headers: {
      Authorization: userToken,
    },
  };

  return axios
    .post(`${celebApi}/admin/markInboxAsSolved`, { inboxId }, httpOptions)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error;
    });
}

export async function fetchPendingRequests(
  userToken: string,
  special: boolean
) {
  const httpOptions = {
    headers: {
      Authorization: userToken,
    },
  };

  return axios
    .get(`${celebApi}/admin/pedingRequests?special=${special}`, httpOptions)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error;
    });
}

export async function updateReportStatus(
  userToken: string,
  solved: boolean,
  reportId: string
) {
  const httpOptions = {
    headers: {
      Authorization: userToken,
    },
  };

  return axios
    .post(
      `${celebApi}/admin/changeReportStatus`,
      { solved, reportId },
      httpOptions
    )
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error;
    });
}

export async function fetchUsersChats(
  userToken: string,
  page: number,
  searchText: string
) {
  const httpOptions = {
    headers: {
      Authorization: userToken,
    },
  };

  return axios
    .get(
      `${celebApi}/admin/getUsersChats?searchText=${searchText}&page=${page}`,
      httpOptions
    )
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error;
    });
}

export const getUserCounterBySort = async (
  date?: string,
  searchFilter?: string
) => {
  return http_request.post(`/admin/getUserCounter`, {
    query: { date, searchFilter },
  });
};

export const getYesOrNoCounterQuizzes = async () => {
  return http_request.get(`/admin/getAnswerCounter`);
};

export async function getTotalRecommendationViewsCounter(userToken: string) {
  const httpOptions = {
    headers: {
      Authorization: userToken,
    },
  };
  return axios
    .get(`${celebApi}/admin/getTotalRecommendationViewsCounter`, httpOptions)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error;
    });
}
