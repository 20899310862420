import toast from "react-hot-toast";

export const notifySuccess = (message: string) => {
  toast.success(message, {
    className: "toast-notification",
    style: { background: "#0d8a0d", color: "#ffffff" },
    position: "top-right",
    duration: 4000,
  });
};

export const notifyError = (message: string) => {
  toast.error(message, {
    className: "toast-notification error",
    style: { background: "#a12727", color: "#ffffff" },
    position: "top-right",
    duration: 4000,
  });
};

export const notifyWarning = (message: string) => {
  toast(message, {
    className: "toast-notification",
    icon: "⚠️",
    style: {
      background: "#ffac4a",
      color: "#ffffff",
    },
    position: "top-right",
    duration: 4000,
  });
};
